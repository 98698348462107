import React, { FC, useState } from "react"

import { GatsbyLinkProps, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faRocket, faHome, faRss } from "@fortawesome/free-solid-svg-icons"

import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

type Props = {
  to: string
  noActive?: boolean
  label: string
  icon: IconProp
}

const LinkItem: FC<Props> = ({ icon, label, noActive = false, to }) => {
  return (
    <NavItem>
      <NavLink
        className="link-item"
        tag={(props) => {
          return (
            <Link to={to} {...props} {...(noActive ? {} : { activeClassName: "active" })}>
              <FontAwesomeIcon icon={icon} /> {label}
            </Link>
          )
        }}
      />
    </NavItem>
  )
}

const PageHeader: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div>
      <Navbar color="light" light expand="sm">
        <NavbarBrand className="mr-auto">Manuel Mauky&#39;s Blog</NavbarBrand>
        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <LinkItem noActive to="/" label="Home" icon={faHome} />
            <LinkItem to="/projects" label="Projects" icon={faRocket} />
            <LinkItem to="/about" label="About" icon={faUser} />

            <UncontrolledDropdown nav>
              <DropdownToggle className="link-item" nav>
                <FontAwesomeIcon icon={faRss} /> Feed
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem href="/atom.xml">Atom</DropdownItem>
                <DropdownItem href="/rss.xml">RSS</DropdownItem>
                <DropdownItem href="/feed.json">JSON</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default PageHeader
