import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"

import { Container } from "reactstrap"

import PageHeader from "./page-header"

import "typeface-muli"
import "typeface-aleo"

const Layout: FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: "Manuel Mauky's Blog" },
            {
              name: "keywords",
              content: "tech, javascript, java, javafx, graphql",
            },
          ]}
        />

        <PageHeader />
        <Container className="main-content">{children}</Container>
        <footer className="footer text-muted">
          <Container>
            &copy; Manuel Mauky | <Link to="/impressum">Impressum</Link>
          </Container>
        </footer>
      </>
    )}
  />
)

export default Layout
